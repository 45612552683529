import type { FC } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Flex } from '@chakra-ui-kraud/react';
import { CustomInput } from '@/shared/components/custom-input';

import styles from './preform-form.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { FormBlock } from '@/shared/components/form-block';
import { FormFieldOperation } from '@/shared/components/form-field-operation';
import { IPreformFormData } from '../model/interface/preform-form.types';

export const PreformForm: FC = () => {
	const { control, setValue, getValues } = useFormContext<IPreformFormData>();

	const sortableOperations = useWatch({ control, name: 'operation_types' });

	return (
		<form>
			<Flex direction="column" gap="32px">
				<FormBlock title="Процесс заготовки">
					<Controller
						control={control}
						name="name"
						render={({ field, fieldState }) => (
							<CustomInput size="md" {...field} label="Наименование" isInvalid={!!fieldState.error} />
						)}
					/>
				</FormBlock>
				<FormBlock
					title="Операции"
					action="Добавить операцию"
					onAction={() => {
						setValue('operation_types', [
							...(sortableOperations ?? []),
							// Новая пустая операция
							{
								name: '',
								id: new Date().getTime() * -1,
								step_id: (sortableOperations[sortableOperations.length - 1]?.step_id | 0) + 5,
							},
						]);
					}}
				>
					<Flex direction="column" gap="10px">
						<ReactSortable
							handle=".handle"
							animation={150}
							list={sortableOperations}
							setList={(newState) => {
								setValue(
									'operation_types',
									newState
										.filter((el) => !!el)
										.map((operation, idx) => ({
											...operation,
											step_id: (idx + 1) * 5,
										})),
								);
							}}
							className={styles['operations']}
						>
							{sortableOperations.map((operation: any, idx: number) => (
								<Controller
									key={operation.id}
									control={control}
									name={`operation_types.${idx}`}
									render={({ field, fieldState }) => {
										return (
											<FormFieldOperation
												step={operation.step_id}
												id={operation.id}
												onDelete={() => {
													console.log(field);
													const newOperations = sortableOperations
														.filter((operation: any) => operation.id !== field.value.id)
														.map((operation: any, idx: number) => ({
															...operation,
															step_id: (idx + 1) * 5,
														}));
													setValue('operation_types', newOperations);
												}}
											>
												<CustomInput
													size="sm"
													label="Наименование операции"
													value={field.value.name}
													isInvalid={!!fieldState.error}
													onChange={(e) => {
														setValue(`operation_types.${idx}`, {
															...getValues(`operation_types.${idx}`),
															name: e.target.value,
														});
													}}
												/>
											</FormFieldOperation>
										);
									}}
								/>
							))}
						</ReactSortable>
					</Flex>
				</FormBlock>
			</Flex>
		</form>
	);
};
