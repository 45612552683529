import * as yup from 'yup';

/**
 * Объект-правил модерации формы для заготовки
 */
export const preformCreateValidation = yup.object({
	symbol: yup.string().min(1).required('Ведите обзначение'),
	name: yup.string().min(1).required('Ведите наименование'),

	operation_types: yup.array().of(
		yup.object({
			name: yup.string().min(1).required('Ведите наименование операции'),
			step_id: yup.number().min(0).required('Ведите наименование шаг'),
		}),
	),
});
