import { Actions, CellTableParagraph, CommonTable, DeleteModal, showErrorToast, showSuccessToast } from '@/shared';
import {
	NumCatalogSortingFields,
	PreformCatalogResponse,
	PreformCatalogSortingFields,
	useDeletePreformMutation,
} from '@/shared/state/api/swagger';
import { useToast } from '@chakra-ui-kraud/react';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { PreformCatalogTableProps } from './num-catalog-table.types';
import styles from './preform-catalog-table.module.scss';

export const PreformCatalogTable: FC<PreformCatalogTableProps> = ({
	pageSize,
	page,
	total,
	setQueryParams,
	data,
	handleClick,
}) => {
	const toast = useToast();

	const [deleteModal, setDeleteModal] = useState(false);
	const [selectedPreform, setSelectedPreform] = useState<PreformCatalogResponse | null>(null);
	const [deletePreform] = useDeletePreformMutation();

	const handleDeletePreform = (preformId: number) => {
		deletePreform({ preformId })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, {
					title: 'Сборочная единица удалена',
					description: `Вы удалили ${selectedPreform?.name}`,
				});
			})
			.catch(() => {
				showErrorToast(toast, {
					title: 'Не удалось удалить сборочную единицу',
					description: `Ошибка при удалении ${selectedPreform?.name}`,
				});
			});
		setDeleteModal(false);
		setSelectedPreform(null);
	};

	const PREFORM_CATALOG_TABLE: ColumnsType<PreformCatalogResponse> = useMemo(() => {
		const onHeaderCell: ColumnsType<PreformCatalogResponse>[number]['onHeaderCell'] = () => ({
			style: {
				width: '100%',
				whiteSpace: 'normal',
			},
		});
		return [
			{
				key: 'name',
				title: 'Наименование',
				dataIndex: 'name',
				sorter: true,
				className: styles['name'],
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
			},
			{
				key: 'actions',
				title: 'Действия',
				align: 'left',
				dataIndex: 'actions',
				className: styles['actions'],
				render: (_: ReactNode, row: PreformCatalogResponse) => {
					return (
						<Actions
							width={120}
							onDelete={() => {
								setSelectedPreform(row);
								setDeleteModal(true);
							}}
							onEdit={() => {
								handleClick?.(row, 'edit');
							}}
							withTemplate
							onCreate={() => {
								handleClick?.(row, 'create');
							}}
							tooltip={{
								duplicate: 'Создать похожый процесс заготовки',
								edit: 'Редактировать процесс заготовки',
								del: 'Удалить процесс заготовки',
							}}
						/>
					);
				},
				onHeaderCell,
				fixed: 'right',
			},
		];
	}, []);

	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter:
				| SorterResult<Partial<PreformCatalogSortingFields>>
				| SorterResult<Partial<PreformCatalogSortingFields>>[],
		) => {
			const sortedBy = Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString();
			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedSortDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			setQueryParams?.((prev) => ({
				...prev,
				order_by: sortedBy as NumCatalogSortingFields,
				order_dir: preparedSortDir,
			}));
		},
		[],
	);

	return (
		<>
			<CommonTable
				columns={PREFORM_CATALOG_TABLE}
				data={data}
				onChange={handleTableChange}
				pageSize={pageSize}
				total={total}
				page={page}
				setQueryParams={setQueryParams}
			/>
			{deleteModal &&
				createPortal(
					<DeleteModal
						isOpen={deleteModal}
						onClose={() => {
							setDeleteModal(false);
							setSelectedPreform(null);
						}}
						onDelete={() => handleDeletePreform(Number(selectedPreform?.id))}
						text={`Вы уверены, что хотите удалить процесс заготовки ${selectedPreform?.name}?`}
						title={'Удаление процесса заготовки'}
					/>,
					document.body,
				)}
		</>
	);
};
